<template>
  <div class="pt-3">
    <Loader v-if="performingRequest || (!payrollQueue || payrollQueue.length == 0)" />
    <div class="dashboard__container--body">

      <PayrollTable v-if="payrollQueue && payrollQueue.length >= 1" :eventAssignments="payrollQueue" />

    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import * as moment from 'moment'
import router from '@/router'
import PayrollTable from '@/components/PayrollTable.vue'
const fb = require('../../firebaseConfig.js')

export default {
  name: 'payrollQueue',
  data: () => ({
    performingRequest: false,
  }),
  components: {
    Loader,
    PayrollTable
  },
  created() {
    if (!this.payrollQueue || this.payrollQueue.length == 0) {
      this.$store.dispatch("getPayrollQueue") 
    }
  },
  computed: {
    ...mapState(['userProfile', 'payrollQueue']),
  },
  methods: {
  
    formatDate(q) {
      if(q) {
        const postedDate = new Date(q.seconds) * 1000;

        return moment.utc(postedDate).local().format('HH:mm:ss A')
        // return moment(postedDate).format('HH:MM A')
      } else {
        return null
      }
    },
    exportRegister() {
      this.performingRequest = true
      const exportHeaders = [
        "Date",
        "First Name",
        "Last Name",
        "Position",
        "Hourly Rate",
        "Clock-In",
        "Clock-Out",
        "Reg Hours",
        "Overtime",
        "2x Overtime",
        "Break Penalty",
        "CC Tips"
      ];
      const exportItems = [];
      for (var key in this.eventAssignments) {
        if (!this.eventAssignments[key].hidden) {

          let inTime = (this.formatDate(this.eventAssignments[key].checkInTimeStamp) || this.eventAssignments[key].inTime)
          let outTime = (this.formatDate(this.eventAssignments[key].checkOutTimeStamp) || this.eventAssignments[key].outTime)

          exportItems.push([
            this.eventAssignments[key].date,
            this.eventAssignments[key].firstName,
            this.eventAssignments[key].lastName,
            this.eventAssignments[key].position,
            this.eventAssignments[key].regRate,
            inTime,
            outTime,
            this.eventAssignments[key].regHours,
            this.eventAssignments[key].otHours,
            this.eventAssignments[key].ot2Hours,
            this.eventAssignments[key].mbp,
            this.eventAssignments[key].tips
          ]);
        }
      }
      this.$gapi.getGapiClient().then(gapi => {
        const exportService = new ExportService(exportHeaders, Object.values(exportItems), gapi);
        exportService.export();
      });
      setTimeout(() => {
        this.performingRequest = false
      }, 2000)
    },
  
  },
  beforeDestroy() {
    this.$store.dispatch("clearPayrollQueue")
    console.log(this)
  }
}
	
</script>